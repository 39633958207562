import React, { useState, useEffect } from "react"
import { HelmetProvider, Helmet } from "react-helmet-async"
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import {
    Box, Paper,
    IconButton,
    Tooltip,
    Typography,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    Slide
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Axios from "axios"
import CloseIcon from "@mui/icons-material/Close";
import BtnSubmit from "../components/BtnSubmit"
import BtnLoading from "../components/BtnLoading"
import Message from "../components/Message";
import "../styles/modal.css"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Slider = () => {
    const [slider, setSlider] = useState([]);
    const [uploads, setUploads] = useState([]);
    const [creado, setCreado] = useState(false)
    const [textoMensaje, setTextoMensaje] = useState("")
    const columns = [
        {
            field: 'path', headerName: 'Imagen', minWidth: 200,
            flex: 1,
            renderCell: (params) => <img src={params.value} style={{ width: 150, height: 150, objectFit: "cover", paddingTop: 10 }} alt="imagen de slider" />,
        },
        { field: 'name', headerName: 'Nombre', minWidth: 150, flex: 1 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Eliminar imagen"
                        className="textPrimary"
                        onClick={() => {
                            var posi = slider.findIndex(obj => obj.id === id);
                            handleDeleteImg(slider[posi]);
                        }}
                        color="inherit"
                    />
                ];
            },
        },
    ];
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState({
        imagenes: "",
        loading: false,
        errorImagenes: false,
        nombreModal: ""
    });

    const { imagenes, loading, errorImagenes, nombreModal } = data;

    useEffect(() => {
        Axios.get(process.env.REACT_APP_API_BASE_URL + "/slider").then(
            (response) => {
                setSlider(response.data.imagenes)
            }
        );
    }, [creado])

    const handleDeleteImg = (img) => {
        if (window.confirm("Esta seguro de eliminar la imagen?")) {
            Axios.delete(process.env.REACT_APP_API_BASE_URL + "/slider/" + img.id + "/" + img.name).then(
                (response) => {
                    setCreado(true);
                    setTextoMensaje(response.data.message)
                }
            );
        }
    }

    const handleChange = (e) => {
        setUploads([...e.target.files]);
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleOpenModal = (categoria, nombre) => {
        setOpenModal(true)
        setCreado(false);

        if (categoria) {
            categoria["nombreModal"] = nombre;
            setData(categoria);
        } else {
            setData({ ...data, nombreModal: nombre })
        }

    };

    const handleCloseModal = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setOpenModal(false)
        setData({
            imagenes: "",
            loading: false,
            errorImagenes: false,
            nombreModal: ""
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorImagenes: false,
            loading: true,
        });

        //verificamos si todos los campos estan completos
        var completo = 1;
        var tem = {
            errorImagenes: false,
            loading: true,
        };

        Object.entries(data).forEach(([key, value]) => {
            if (key === "imagenes" && value.length === 0) {
                tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa una imagen";
                tem["loading"] = false;
                completo = 0;
            }
        });

        if (!completo) {
            setData({
                ...data,
                errorImagenes: tem["errorImagenes"],
                loading: tem["loading"],
            });
        } else {
            var formData = new FormData();
            uploads.forEach((file, index) => {
                formData.append(`file${index}`, file);
            });

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            Axios.post(process.env.REACT_APP_API_BASE_URL + "/slider", formData, config).then((response) => {
                if (response.data.error === "imagen") {
                    setData({
                        ...data,
                        errorImagenes: response.data.message,
                        loading: false
                    });
                } else {
                    setCreado(true);
                    setTextoMensaje(response.data.message)
                    handleCloseModal()
                }
            })
        }
    }

    function EditToolbar() {
        return (
            <GridToolbarContainer
                sx={{
                    justifyContent: "space-between",
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}>
                <Typography
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Slider
                </Typography>
                <Tooltip title="Añadir imagenes">
                    <IconButton onClick={() => { handleOpenModal({ ...data }, "Añadir imagenes") }}>
                        <AddCircleIcon />
                    </IconButton>
                </Tooltip>
            </GridToolbarContainer>
        );
    }

    return <HelmetProvider>
        <Helmet>
            <title>Slider | {process.env.REACT_APP_NAME}</title>
        </Helmet>

        <Box sx={{ width: "100%" }}>
            {(creado) ? <Message texto={textoMensaje} /> : ""}
            <Paper sx={{ width: "100%", mb: 2 }}>
                <Dialog
                    className="modal"
                    open={openModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseModal}
                    fullWidth={true}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {nombreModal}
                        <IconButton
                            onClick={handleCloseModal}
                            aria-label="close"
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "unset" }}>
                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                            <TextField
                                type="file"
                                name="imagenes"
                                inputProps={{ accept: 'image/*', multiple: true }}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                label="Imagenes"
                                onChange={handleChange}
                                value={imagenes}
                                error={errorImagenes ? true : false}
                                helperText={errorImagenes}
                                style={{ marginBottom: "40px" }} />
                            {(nombreModal === "Ver compra") ? "" : (loading)
                                ? <BtnLoading
                                    texto={"Añadiendo..."} />
                                : <BtnSubmit
                                    texto={"Añadir"} />
                            }
                        </form>
                    </DialogContent>
                </Dialog>
                <DataGrid
                    autoHeight
                    getRowHeight={() => 170}
                    rows={slider}
                    columns={columns}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 20]}
                    slots={{
                        toolbar: EditToolbar,
                    }}
                />
            </Paper>
        </Box>
    </HelmetProvider>
}

export default Slider