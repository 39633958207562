import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

const BtnLoading = ({ texto }) => {
  return (
    <LoadingButton
      className="btn btn_loading"
      loading
      loadingPosition="start"
      startIcon={<SaveIcon />}
      variant="outlined"
    >
      {texto}
    </LoadingButton>
  );
};

export default BtnLoading;
