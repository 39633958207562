import React, { useContext } from "react"
import { Link, NavLink } from "react-router-dom";
import logo from "../img/logo_josemar.svg"
import "../styles/navAdmin.css"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../context/auth";
import logout from "../img/logout.svg"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import CollectionsIcon from '@mui/icons-material/Collections';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PeopleIcon from '@mui/icons-material/People';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';

const NavAdmin = ({ children }) => {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleCloseSesion = () => {
        localStorage.clear();
        navigate("/login");
    }

    return <div className="contenedorMainAdmin">
        <header>
            <div className="menuHeader">
                <IconButton onClick={toggleDrawer(true)}>
                    <MenuIcon style={{ fontSize: 30 }} />
                </IconButton>
            </div>
            <div className="userInfo">
                <div className="profile">
                    {(user && user.nombre !== "" && user.apellido !== "") ?
                        <><p>{user.nombre[0] + user.apellido[0]}</p>
                            <div className="profile_info">
                                <p>{user.nombre + " " + user.apellido}</p>
                                <p>{user.email}</p>
                            </div></>
                        : ""
                    }
                </div>
            </div>
        </header>
        <main>{children}</main>
        <div className="containerNavAdmin">
            <Link to="/admin">
                <img src={logo} className="logo" alt="Logo" />
            </Link>
            <NavLink
                to="/admin"
            >
                {({ isActive }) => (
                    <div className="iconGroup">
                        {(isActive) ? <CollectionsIcon className="iconActive" /> : <CollectionsOutlinedIcon />}
                        <p className={isActive ? "link-active" : ""}>Slider</p>
                    </div>
                )}
            </NavLink>
            <NavLink
                to="/proyectos"
            >
                {({ isActive }) => (
                    <div className="iconGroup">
                        {(isActive) ? <MapsHomeWorkIcon className="iconActive" /> : <MapsHomeWorkOutlinedIcon />}
                        <p className={isActive ? "link-active" : ""}>Proyectos</p>
                    </div>
                )}
            </NavLink>
            <NavLink
                to="/usuarios"
            >
                {({ isActive }) => (
                    <div className="iconGroup">
                        {(isActive) ? <PeopleIcon className="iconActive" /> : <PeopleOutlinedIcon />}
                        <p className={isActive ? "link-active" : ""}>Usuarios</p>
                    </div>
                )}
            </NavLink>
            <div className="iconGroup cerrarSesion" onClick={handleCloseSesion}>
                <img src={logout} alt="Icono de cerrar sesion" />
                <p>Cerrar sesión</p>
            </div>
        </div>
        <Drawer open={open} onClose={toggleDrawer(false)}>
            <div className="containerNavMobileAdmin">
                <Link to="/">
                    <img src={logo} className="logo" alt="Logo" />
                </Link>
                <NavLink
                    to="/admin"
                >
                    {({ isActive }) => (
                        <div className="iconGroup" onClose={toggleDrawer(false)}>
                            {(isActive) ? <CollectionsIcon className="iconActive" /> : <CollectionsOutlinedIcon />}
                            <p className={isActive ? "link-active" : ""}>Slider</p>
                        </div>
                    )}
                </NavLink>
                <NavLink
                    to="/proyectos"
                >
                    {({ isActive }) => (
                        <div className="iconGroup" onClose={toggleDrawer(false)}>
                            {(isActive) ? <MapsHomeWorkIcon className="iconActive" /> : <MapsHomeWorkOutlinedIcon />}
                            <p className={isActive ? "link-active" : ""}>Proyectos</p>
                        </div>
                    )}
                </NavLink>
                <NavLink
                    to="/usuarios"
                >
                    {({ isActive }) => (
                        <div className="iconGroup" onClose={toggleDrawer(false)}>
                            {(isActive) ? <PeopleIcon className="iconActive" /> : <PeopleOutlinedIcon />}
                            <p className={isActive ? "link-active" : ""}>Usuarios</p>
                        </div>
                    )}
                </NavLink>
                <div className="iconGroup cerrarSesion" onClick={handleCloseSesion}>
                    <img src={logout} alt="Icono de cerrar sesion" />
                    <p>Cerrar sesión</p>
                </div>
            </div>
        </Drawer>
    </div>
}

export default NavAdmin