import React, { useEffect, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async";
import "../styles/Galeria.css"
import Axios from "axios"
import {
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Slide,
    Box,
    Button,
    MobileStepper,
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Galeria = () => {
    const [proyectos, setProyectos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [maxSteps, setMaxSteps] = useState(0)
    const theme = useTheme();
    const [imgsModal, setImgsModal] = useState(0);

    useEffect(() => {
        Axios.get(process.env.REACT_APP_API_BASE_URL + "/proyectos").then(
            (response) => {
                setProyectos(response.data.proyectos)
                setLoading(false)
            }
        );
    }, [])

    const findElement = (array, searchedAge) => {
        for (let i = 0; i < array.length; i++) {
            const element = array[i];
            if (element.path === searchedAge) {
                return element;
            }
        }
        return -1;
    }

    const handleOpenModal = (imgPortada, imagenes) => {
        setActiveStep(0)
        if (findElement(imagenes, imgPortada) === -1 && findElement(imgsModal, imgPortada) === -1) {
            imagenes.splice(0, 0, { path: imgPortada });
        }
        setMaxSteps(imagenes.length);
        setImgsModal(imagenes);
        setOpenModal(true);
    };

    const handleCloseModal = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setOpenModal(false);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return <HelmetProvider>
        <Helmet>
            <title>Galería de Proyectos | Josemar Constructora</title>
        </Helmet>

        <div className="galeriaContainer" id="galeria">
            <h1>Galería de proyectos</h1>

            {loading ? <>
                <div className="loaderContainer">
                    <div className="loader" id="loader-1"></div>
                </div>
            </>
                :
                <div className="galeria">
                    {proyectos.map(function (proyecto, index) {
                        return <div key={index} className="proyect">
                            <img src={proyecto.portada} alt="imagen de casa" />
                            <p>{proyecto.nombre}</p>
                            <p className="verImagenes" onClick={() => handleOpenModal(proyecto.portada, proyecto.imagenes)}>Ver imagenes</p>
                        </div>
                    })}
                </div>
            }
        </div>
        <Dialog
            className="modal"
            open={openModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseModal}
            fullWidth={true}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-title">
                Imagenes
                <IconButton
                    onClick={handleCloseModal}
                    aria-label="close"
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ overflowY: "unset" }}>
                <Box sx={{ flexGrow: 1 }}>
                    <img src={(imgsModal.length > 0) ? imgsModal[activeStep].path : ""} className="imgProyectModal" alt="imagen de vivienda" />
                    <MobileStepper
                        ref={(node) => {
                            if (node) {
                                node.style.setProperty("margin", " 0 auto", "important");
                            }
                        }}
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                            >
                                Siguiente
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                                Anterior
                            </Button>
                        }
                    />
                </Box>
            </DialogContent>
        </Dialog>

    </HelmetProvider>
}

export default Galeria;