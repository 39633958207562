import { createContext, useEffect, useState } from "react";
import Axios from "axios";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  Axios.defaults.withCredentials = true;

  const [user, setUser] = useState({
    id: "",
    nombre: "",
    apellido: "",
    email: "",
    tipo: ""
  });

  useEffect(() => {
    setUser({
      id: localStorage.getItem("id"),
      nombre: localStorage.getItem("nombre"),
      apellido: localStorage.getItem("apellido"),
      email: localStorage.getItem("email"),
      tipo: localStorage.getItem("tipo")
    })
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
