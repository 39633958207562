import React from "react";
import { Button } from "@mui/material";

const btnSubmit = ({ texto, link = null }) => {
  return (
    <Button
      type="submit"
      variant="contained"
      href={link ?? link}
      className="btn btn_submit"
      color="primary"
    >
      {texto}
    </Button>
  );
};

export default btnSubmit;
