import React, { useState, useEffect, useContext } from 'react';
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer
} from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import {
    Box, Paper,
    IconButton,
    Tooltip,
    Typography,
    TextField,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    Slide
} from '@mui/material';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Axios from "axios"
import { AuthContext } from "../context/auth";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import BtnSubmit from "../components/BtnSubmit"
import BtnLoading from "../components/BtnLoading"
import Message from "../components/Message";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Usuarios() {
    const [creado, setCreado] = useState(false)
    const [usuarios, setUsuarios] = useState([])
    const { setUser } = useContext(AuthContext)
    const columns = [
        { field: 'nombre', headerName: 'Nombre', minWidth: 150, flex: 1 },
        { field: 'apellido', headerName: 'Apellido', minWidth: 150, flex: 1 },
        { field: 'email', headerName: 'Correo electrónico', minWidth: 150, flex: 1 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => {
                            var posi = usuarios.findIndex(obj => obj.id === id);
                            handleOpenModal(usuarios[posi], "Editar usuario");
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => eliminarUsuario(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState({
        email: "",
        password: "",
        nombre: "",
        apellido: "",
        loading: false,
        errorEmail: false,
        errorPassword: false,
        errorNombre: false,
        errorApellido: false,
        nombreModal: "",
    });
    const { email, password, nombre, apellido, loading, errorEmail,
        errorPassword, errorNombre, errorApellido,
        nombreModal } = data;
    const [showPass, setShowPass] = useState(false);
    const [textoMensaje, setTextoMensaje] = useState("")

    const handleOpenModal = (usuario, nombre) => {
        setOpenModal(true)
        setCreado(false);

        if (usuario) {
            usuario["nombreModal"] = nombre;
            usuario["activo"] = (usuario["activo"] === 1)
            setData(usuario);
        } else {
            setData({ ...data, nombreModal: nombre })
        }

    };

    const handleChange = (e) => {
        if (e.target.name === "activo") {
            setData({ ...data, [e.target.name]: e.target.checked })
        } else {
            setData({ ...data, [e.target.name]: e.target.value });
        }
    };

    const handlePassword = () => {
        setShowPass(!showPass);
    };

    const handleCloseModal = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setOpenModal(false)
        setData({
            nombre: "",
            email: "",
            password: "",
            apellido: ""
        });
    };

    const validarEmail = (email) => {
        if (
            /* eslint-disable-next-line */
            /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                email
            )
        ) {
            return true;
        }
    };


    useEffect(() => {
        Axios.get(process.env.REACT_APP_API_BASE_URL + "/usuarios/").then(
            (response) => {
                setUsuarios(response.data.usuarios)
            }
        );
    }, [creado])

    const handleSubmit = async (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorEmail: false,
            errorPassword: false,
            errorApellido: false,
            errorNombre: false,
            loading: true,
        });

        //verificamos si todos los campos estan completos
        var completo = 1;
        var tem = {
            errorEmail: false,
            errorPassword: false,
            errorApellido: false,
            errorNombre: false,
            loading: true,
        };

        Object.entries(data).forEach(([key, value]) => {
            if (value.length === 0 && (key === "nombre" ||
                key === "email" || key === "password")) {
                if (key === "email") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un correo electrónico";
                } else if (key === "password") {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa una contraseña";
                } else {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un " + key;
                }
                tem["loading"] = false;
                completo = 0;
            }
        });

        if (!completo) {
            setData({
                ...data,
                errorEmail: tem["errorEmail"],
                errorPassword: tem["errorPassword"],
                errorApellido: tem["errorApellido"],
                errorNombre: tem["errorNombre"],
                loading: tem["loading"],
            });
        } else if (!validarEmail(email)) {
            setData({
                ...data,
                errorEmail: "Ingresa un correo valido",
                loading: false,
            });
        } else {
            if (nombreModal === "Editar usuario") {
                Axios.post(process.env.REACT_APP_API_BASE_URL + "/usuarios", {
                    email: email,
                    nombre: nombre,
                    apellido: apellido
                }).then((response) => {
                    if (response.data.message) {
                        if (email === localStorage.getItem("email")) {
                            localStorage.setItem("nombre", nombre)
                            localStorage.setItem("apellido", apellido)
                            setUser({
                                id: localStorage.getItem("id"),
                                nombre: localStorage.getItem("nombre"),
                                apellido: localStorage.getItem("apellido"),
                                email: localStorage.getItem("email"),
                                tipo: localStorage.getItem("tipo")
                            })
                        }
                        setCreado(true);
                        setTextoMensaje("Usuario editado!")
                        handleCloseModal();
                    }
                })
            } else {
                Axios.post(process.env.REACT_APP_API_BASE_URL + "/usuarios/nuevo", {
                    email: email,
                    password: password,
                    nombre: nombre,
                    apellido: apellido,
                }).then((response) => {
                    if (response.data.error === "email") {
                        setData({
                            ...data,
                            errorEmail: response.data.message,
                            errorPassword: false,
                            loading: false
                        });
                    } else {
                        setCreado(true);
                        setTextoMensaje("Usuario creado!")
                        handleCloseModal();
                    }
                })
            }
        }
    }

    const eliminarUsuario = (idUsuario) => {
        if (window.confirm("Esta seguro de eliminar al usuario?")) {
            Axios.delete(process.env.REACT_APP_API_BASE_URL + "/usuarios/" + idUsuario).then(
                (response) => {
                    setCreado(true);
                    setTextoMensaje(response.data.message)
                }
            );
        }
    }

    function EditToolbar() {
        return (
            <GridToolbarContainer
                sx={{
                    justifyContent: "space-between",
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}>
                <Typography
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Usuarios
                </Typography>
                <Tooltip title="Añadir usuario">
                    <IconButton onClick={() => handleOpenModal({ ...data }, "Añadir usuario")}>
                        <AddCircleIcon />
                    </IconButton>
                </Tooltip>
            </GridToolbarContainer>
        );
    }


    return (
        <HelmetProvider>
            <Helmet>
                <title>Usuarios | {process.env.REACT_APP_NAME}</title>
            </Helmet>
            <Box sx={{ width: "100%" }}>
                {(creado) ? <Message texto={textoMensaje} /> : ""}
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <Dialog
                        className="modal modal_carpetas"
                        open={openModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseModal}
                        fullWidth={true}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {nombreModal}
                            <IconButton
                                onClick={handleCloseModal}
                                aria-label="close"
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent style={{ overflowY: "unset" }}>
                            <form onSubmit={handleSubmit}>
                                <div className="textfield_group">
                                    <TextField
                                        name="nombre"
                                        onChange={handleChange}
                                        value={nombre}
                                        style={{ marginRight: "30px" }}
                                        label="Nombre"
                                        error={errorNombre ? true : false}
                                        helperText={errorNombre} />
                                    <TextField
                                        name="apellido"
                                        onChange={handleChange}
                                        value={apellido}
                                        label="Apellido"
                                        error={errorApellido ? true : false}
                                        helperText={errorApellido} />
                                </div>
                                <TextField
                                    name="email"
                                    onChange={handleChange}
                                    value={email}
                                    style={{ marginBottom: "22px" }}
                                    label="Correo electrónico"
                                    error={errorEmail ? true : false}
                                    inputProps={{
                                        readOnly: (nombreModal === "Editar usuario"),
                                    }}
                                    helperText={errorEmail} />
                                {(nombreModal === "Añadir usuario")
                                    ? <TextField label="Contraseña"
                                        name="password"
                                        onChange={handleChange}
                                        type={showPass ? "text" : "password"}
                                        value={password}
                                        error={errorPassword ? true : false}
                                        helperText={errorPassword}
                                        style={{ marginBottom: "22px" }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end" onClick={handlePassword}>
                                                        {(showPass) ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} />
                                    : <></>
                                }
                                {loading
                                    ? <BtnLoading
                                        texto={(nombreModal === "Editar usuario") ? "Editando..." : "Añadiendo..."} />
                                    : <BtnSubmit
                                        texto={(nombreModal === "Editar usuario") ? "Editar" : "Añadir"} />
                                }
                            </form>
                        </DialogContent>
                    </Dialog>
                    <DataGrid
                        autoHeight
                        rows={usuarios}
                        columns={columns}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 20]}
                        slots={{
                            toolbar: EditToolbar,
                        }}
                    />
                </Paper>
            </Box >
        </HelmetProvider>
    );
}