import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "../styles/Home.css"
import vivienda120 from "../img/vivienda_120.png"
import vivienda72 from "../img/vivienda_72.jpg"
import vivienda50 from "../img/vivienda_50.jpg"
import nosotros from "../img/nosotros-01.jpg"
import { HashLink as Link } from 'react-router-hash-link';
import Axios from "axios"

const Home = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        Axios.get(process.env.REACT_APP_API_BASE_URL + "/slider").then(
            (response) => {
                setImages(response.data.imagenes)
            }
        );
    }, [])

    var tiempoVisible = 9; // Define cuántos segundos estará visible cada imagen
    var slider = document.getElementById('slider');

    images.forEach(function (imagen, indice) {
        var imgElement = document.createElement('img');
        imgElement.src = imagen.path;
        imgElement.style.animation = `cambio ${(tiempoVisible + 2) * images.length}s ${indice * (tiempoVisible + 2)}s infinite`;
        slider.appendChild(imgElement);
    });

    var styleElement = document.createElement('style');
    styleElement.innerHTML = `
        @keyframes cambio {
            0% { opacity: 0; }
            ${(100 / ((tiempoVisible + 2) * images.length)) * 2}% { opacity: 1; }
            ${(100 / ((tiempoVisible + 2) * images.length)) * tiempoVisible}% { opacity: 1; }
            ${(100 / ((tiempoVisible + 2) * images.length)) * (tiempoVisible + 2)}% { opacity: 0.6; }
            ${(100 / ((tiempoVisible + 2) * images.length)) * (tiempoVisible + 3)}% { opacity: 0; }
        }
    `;
    document.head.appendChild(styleElement);


    return <HelmetProvider>
        <Helmet>
            <title>Josemar Constructora</title>
        </Helmet>

        <div className="sliderContainer">
            <div className="slider" id="slider">
            </div>
            <div className="sliderInfo">
                <h1 className="sliderTitle">Lo mejor en <br /> Construcciones</h1>
                <p className="sliderBtn"><Link to="/contacto">Contactame</Link></p>
            </div>
        </div>
        <div className="progressContainer">
            <div>
                <p>+15</p>
                <p>Años de trayectoria</p>
            </div>
            <div>
                <p>+200</p>
                <p>Obras realizadas</p>
            </div>
            <div>
                <p>+10</p>
                <p>Emprendimientos inmobiliarios</p>
            </div>
            <div>
                <p>+5</p>
                <p>Ventas inmobiliarias</p>
            </div>
        </div>
        <div className="serviciosContainer">
            <h2>Nuestros servicios</h2>
            <div className="servicios">
                <div>
                    <p>Construcciones en general</p>
                </div>
                <div>
                    <p>Instalación de blindex</p>
                </div>
                <div>
                    <p>Instalación de cielorraso</p>
                </div>
            </div>
        </div>
        <div className="proyectosContainer">
            <h2>Galería de proyectos</h2>
            <div className="proyectos">
                <div>
                    <img src={vivienda120} alt="imagen de vivienda" />
                    <p>Vivienda 120 mt2</p>
                </div>
                <div>
                    <img src={vivienda72} alt="imagen de vivienda" />
                    <p>Vivienda 72 mt2</p>
                </div>
                <div>
                    <img src={vivienda50} alt="imagen de vivienda" />
                    <p>Vivienda 50 mt2</p>
                </div>
            </div>
            <p className="irProyectos" id="nosotros"><Link to="/galeriaProyectos/#galeria">Ver más proyectos</Link></p>
        </div>
        <div className="nosotrosContainer">
            <div>
                <img src={nosotros} alt="imagen de casas" />
            </div>
            <div>
                <h2>Sobre nosotros</h2>
                <p>Somos una empresa dedicada al diseño y ejecución de proyectos de construcciones civiles,
                    con una amplia experiencia en el sector. Estamos en un proceso de desarrollo continuo
                    para poder brindar el mejor servicio posible a nuestros clientes</p>
            </div>
        </div>
        <div className="creditoContainer">
            <h2>Crédito AFD</h2>
            <p>Financiamiento para la construcción de la primera vivienda</p>
            <Link to="https://youtu.be/QeeIhB5Sfsw?si=H3X07E1W0EwmmLEE" target="_blank" className="saberMas">Saber más</Link>
        </div>
    </HelmetProvider>
}

export default Home;