import React, { useState, useEffect } from "react"
import { HelmetProvider, Helmet } from "react-helmet-async"
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import {
    Box, Paper,
    IconButton,
    Tooltip,
    Typography,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    Slide
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Axios from "axios"
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from "@mui/icons-material/Close";
import BtnSubmit from "../components/BtnSubmit"
import BtnLoading from "../components/BtnLoading"
import Message from "../components/Message";
import "../styles/modal.css"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Proyectos = () => {
    const [proyectos, setProyectos] = useState([]);
    const [posiPro, setPosiPro] = useState(0);
    const [uploads, setUploads] = useState([]);
    const [portad, setPortad] = useState([]);
    const [creado, setCreado] = useState(false)
    const [textoMensaje, setTextoMensaje] = useState("")
    const columns = [
        {
            field: 'portada', headerName: 'Portada', minWidth: 200,
            flex: 1,
            renderCell: (params) => <img src={params.value} style={{ width: 150, height: 150, objectFit: "cover", paddingTop: 10 }} alt="imagen de slider" />,
        },
        { field: 'nombre', headerName: 'Nombre', minWidth: 150, flex: 1 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => {
                            var posi = proyectos.findIndex(obj => obj.id === id);
                            setPosiPro(posi);
                            handleOpenModal(proyectos[posi].nombre, "Editar proyecto");
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Eliminar imagen"
                        className="textPrimary"
                        onClick={() => {
                            handleDeleteProyecto(id, "");
                        }}
                        color="inherit"
                    />
                ];
            },
        },
    ];
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState({
        nombre: "",
        portada: "",
        imagenes: "",
        loading: false,
        errorNombre: false,
        errorPortada: false,
        errorImagenes: false,
        nombreModal: ""
    });

    const { imagenes,
        nombre,
        portada,
        loading,
        errorImagenes,
        errorNombre,
        errorPortada,
        nombreModal
    } = data;

    useEffect(() => {
        Axios.get(process.env.REACT_APP_API_BASE_URL + "/proyectos").then(
            (response) => {
                setProyectos(response.data.proyectos)
            }
        );
    }, [creado])

    const handleDeleteProyecto = (id, name) => {
        if (name === "") {
            if (window.confirm("Esta seguro de eliminar el proyecto?")) {
                Axios.delete(process.env.REACT_APP_API_BASE_URL + "/proyectos/" + id).then(
                    (response) => {
                        setCreado(true);
                        setTextoMensaje(response.data.message)
                    }
                );
            }
        } else {
            if (window.confirm("Esta seguro de eliminar la imagen?")) {
                Axios.delete(process.env.REACT_APP_API_BASE_URL + "/proyectos/" + id + "/" + name).then(
                    (response) => {
                        setCreado(true);
                        setTextoMensaje(response.data.message)
                    }
                );
            }
        }
    }

    const handleChange = (e) => {
        if (e.target.name === "imagenes") {
            setUploads([...e.target.files]);
        } else if (e.target.name === "portada") {
            setPortad(e.target.files[0]);
        }
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleOpenModal = (categoria, nombre) => {
        setOpenModal(true)
        setCreado(false);

        if (categoria) {
            setData({ ...data, nombre: categoria, nombreModal: nombre });
        } else {
            setData({ ...data, nombreModal: nombre })
        }

    };

    const handleCloseModal = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setOpenModal(false)
        setData({
            nombre: "",
            portada: "",
            imagenes: "",
            loading: false,
            errorNombre: false,
            errorPortada: false,
            errorImagenes: false,
            nombreModal: ""
        });
        setPortad([]);
        setUploads([]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorImagenes: false,
            errorPortada: false,
            errorNombre: false,
            loading: true,
        });

        //verificamos si todos los campos estan completos
        var completo = 1;
        var tem = {
            errorImagenes: false,
            errorPortada: false,
            errorNombre: false,
            loading: true,
        };

        if (nombreModal === "Editar proyecto") {
            Object.entries(data).forEach(([key, value]) => {
                if (key === "nombre" && value.length === 0) {
                    tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un " + key;
                    tem["loading"] = false;
                    completo = 0;
                }
            });
        } else {
            Object.entries(data).forEach(([key, value]) => {
                if ((key === "imagenes" || key === "portada" || key === "nombre") && value.length === 0) {
                    if (key === "imagenes" || key === "portada") {
                        tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa una imagen";
                    } else {
                        tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un " + key;
                    }
                    tem["loading"] = false;
                    completo = 0;
                }
            });
        }

        if (!completo) {
            setData({
                ...data,
                errorImagenes: tem["errorImagenes"],
                errorNombre: tem["errorNombre"],
                errorPortada: tem["errorPortada"],
                loading: tem["loading"],
            });
        } else {
            var formData = new FormData();
            formData.append(`file0`, portad);
            uploads.forEach((file, index) => {
                formData.append(`file${index + 1}`, file);
            });
            formData.append("nombre", nombre);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            if (nombreModal === "Editar proyecto") {
                let elegido = proyectos[posiPro];
                Axios.post(process.env.REACT_APP_API_BASE_URL + "/proyectos/editar/" + elegido.id, formData, config).then((response) => {
                    if (response.data.error === "imagen") {
                        setData({
                            ...data,
                            errorImagenes: response.data.message,
                            loading: false
                        });
                    } else {
                        setCreado(true);
                        setTextoMensaje(response.data.message)
                        handleCloseModal()
                    }
                });
            } else {
                Axios.post(process.env.REACT_APP_API_BASE_URL + "/proyectos", formData, config).then((response) => {
                    if (response.data.error === "imagen") {
                        setData({
                            ...data,
                            errorImagenes: response.data.message,
                            loading: false
                        });
                    } else {
                        setCreado(true);
                        setTextoMensaje(response.data.message)
                        handleCloseModal()
                    }
                });
            }
        }
    }

    function EditToolbar() {
        return (
            <GridToolbarContainer
                sx={{
                    justifyContent: "space-between",
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}>
                <Typography
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Proyectos
                </Typography>
                <Tooltip title="Añadir proyecto">
                    <IconButton onClick={() => { handleOpenModal("", "Añadir proyecto") }}>
                        <AddCircleIcon />
                    </IconButton>
                </Tooltip>
            </GridToolbarContainer>
        );
    }

    return <HelmetProvider>
        <Helmet>
            <title>Proyectos | {process.env.REACT_APP_NAME}</title>
        </Helmet>

        <Box sx={{ width: "100%" }}>
            {(creado) ? <Message texto={textoMensaje} /> : ""}
            <Paper sx={{ width: "100%", mb: 2 }}>
                <Dialog
                    className="modal"
                    open={openModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseModal}
                    fullWidth={true}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {nombreModal}
                        <IconButton
                            onClick={handleCloseModal}
                            aria-label="close"
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "unset" }}>
                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                            <TextField
                                name="nombre"
                                variant="outlined"
                                label="Nombre"
                                onChange={handleChange}
                                value={nombre}
                                error={errorNombre ? true : false}
                                helperText={errorNombre}
                                style={{ marginBottom: "22px" }} />
                            <TextField
                                type="file"
                                name="portada"
                                inputProps={{ accept: 'image/*', multiple: false }}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                label="Portada"
                                onChange={handleChange}
                                value={portada}
                                error={errorPortada ? true : false}
                                helperText={errorPortada}
                                style={{ marginBottom: "22px" }} />
                            <TextField
                                type="file"
                                name="imagenes"
                                inputProps={{ accept: 'image/*', multiple: true }}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                label="Imagenes"
                                onChange={handleChange}
                                value={imagenes}
                                error={errorImagenes ? true : false}
                                helperText={errorImagenes}
                                style={{ marginBottom: "40px" }} />
                            {loading
                                ? <BtnLoading
                                    texto={(nombreModal === "Editar proyecto") ? "Editando..." : "Añadiendo..."} />
                                : <BtnSubmit
                                    texto={(nombreModal === "Editar proyecto") ? "Editar" : "Añadir"} />
                            }
                            {nombreModal === "Editar proyecto" ? <List sx={{ width: '100%', bgcolor: 'background.paper', maxHeight: 150, marginTop: 3 }}>
                                {proyectos.length > 0 && proyectos[0].imagenes.map((imagen) => (
                                    <ListItem
                                        alignItems="center"
                                        key={imagen.name}
                                        disableGutters
                                        secondaryAction={
                                            <IconButton aria-label="delete" onClick={() => handleDeleteProyecto(imagen.id, imagen.name)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar sx={{ height: 70, width: 70, paddingRight: 2, borderRadius: 0 }} alt="imagen de vivienda" src={imagen.path} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            sx={{ wordBreak: "break-all" }}
                                            primary={imagen.name}
                                        />
                                    </ListItem>
                                ))}
                            </List> : <></>}
                        </form>
                    </DialogContent>
                </Dialog>
                <DataGrid
                    autoHeight
                    getRowHeight={() => 170}
                    rows={proyectos}
                    columns={columns}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 20]}
                    slots={{
                        toolbar: EditToolbar,
                    }}
                />
            </Paper>
        </Box>
    </HelmetProvider>
}

export default Proyectos