import React, { useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom"
import "../styles/Contacto.css"
import { TextField } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import BtnSubmit from "../components/BtnSubmit"
import BtnLoading from "../components/BtnLoading"
import Message from "../components/Message";
import Axios from "axios"

const Contacto = () => {
    const [data, setData] = useState({
        nombre: "",
        telefono: "",
        correo: "",
        comentario: "",
        errorNombre: false,
        errorTelefono: false,
        errorComentario: false,
        errorCorreo: false,
        loading: false
    })
    const [creado, setCreado] = useState(false)
    const [textoMensaje, setTextoMensaje] = useState("")

    const { nombre, telefono, correo, comentario,
        loading,
        errorNombre,
        errorComentario,
        errorCorreo,
        errorTelefono
    } = data

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const validarEmail = (email) => {
        if (
            /* eslint-disable-next-line */
            /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                email
            )
        ) {
            return true;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorNombre: false,
            errorTelefono: false,
            errorComentario: false,
            errorCorreo: false,
            loading: true
        });

        //verificamos si todos los campos estan completos
        var completo = 1;
        var tem = {
            errorNombre: false,
            errorTelefono: false,
            errorComentario: false,
            errorCorreo: false,
            loading: true,
        };

        Object.entries(data).forEach(([key, value]) => {
            if (value.length === 0 && (key === "nombre" ||
                key === "correo" || key === "telefono" || key === "comentario")) {
                tem["error" + key.charAt(0).toUpperCase() + key.substring(1, key.length)] = "Ingresa un " + key;
                tem["loading"] = false;
                completo = 0;
            }
        });

        if (!completo) {
            setData({
                ...data,
                errorNombre: tem["errorNombre"],
                errorTelefono: tem["errorTelefono"],
                errorComentario: tem["errorComentario"],
                errorCorreo: tem["errorCorreo"],
                loading: tem["loading"],
            });
        } else if (!validarEmail(correo)) {
            setData({
                ...data,
                errorCorreo: "Ingresa un correo valido",
                loading: false,
            });
        } else {
            Axios.post(process.env.REACT_APP_API_BASE_URL + "/contacto", {
                correo: correo,
                nombre: nombre,
                telefono: telefono,
                comentarios: comentario
            }).then((response) => {
                if (response.data.message) {
                    setCreado(true);
                    setData({
                        nombre: "",
                        telefono: "",
                        correo: "",
                        comentario: "",
                        errorNombre: false,
                        errorTelefono: false,
                        errorComentario: false,
                        errorCorreo: false,
                        loading: false
                    })
                    setTextoMensaje(response.data.message)
                }
            })
        }
    }

    return <HelmetProvider>
        <Helmet>
            <title>Contacto | Josemar Constructora</title>
        </Helmet>

        <div className="contactoContainer">
            {(creado) ? <Message texto={textoMensaje} /> : ""}
            <h1>Contacto</h1>
            <div className="contactoPage">
                <div className="info">
                    <Link to="https://maps.app.goo.gl/qAhJ8EYjbhckmjSL7"><LocationOnIcon className="icon" /> Policarpo Cañete esq. San Blas, Fernando de la Mora</Link>
                    <Link to="mailto:ventas@josemar.com.py"><MailOutlineIcon className="icon" /> ventas@josemar.com.py</Link>
                    <Link to="tel:0982 552582"><PhoneIcon className="icon" /> 0982 552582</Link>
                    <iframe className="mapa" title="Mapa Josemar Constructora" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14424.206027463766!2d-57.5710308!3d-25.3360526!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x945da93cb2b369e7%3A0x55b5032ddbeea22b!2sJosemar%20Constructora!5e0!3m2!1ses!2spy!4v1714933960015!5m2!1ses!2spy" width="600" height="450" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <form className="form" onSubmit={handleSubmit}>
                    <p className="formTitle">Envianos un correo</p>
                    <TextField
                        className="input"
                        name="nombre"
                        onChange={handleChange}
                        value={nombre}
                        error={errorNombre ? true : false}
                        helperText={errorNombre}
                        label="Nombre y Apellido"
                    />
                    <TextField
                        className="input"
                        name="telefono"
                        onChange={handleChange}
                        value={telefono}
                        label="Teléfono"
                        error={errorTelefono ? true : false}
                        helperText={errorTelefono}
                    />
                    <TextField
                        className="input"
                        name="correo"
                        type="email"
                        onChange={handleChange}
                        value={correo}
                        label="Correo Electrónico"
                        error={errorCorreo ? true : false}
                        helperText={errorCorreo}
                    />
                    <TextField
                        className="input"
                        name="comentario"
                        onChange={handleChange}
                        value={comentario}
                        label="Comentarios"
                        error={errorComentario ? true : false}
                        helperText={errorComentario}
                        multiline
                        rows={4}
                    />
                    {loading
                        ? <BtnLoading
                            texto={"Enviado..."} />
                        : <BtnSubmit
                            texto={"Enviar"} />
                    }
                </form>
            </div>
        </div>

    </HelmetProvider>
}

export default Contacto;