import './App.css';
import Home from "./pages/Home"
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { HashRouter, Routes, Route } from "react-router-dom";
import Nav from "./components/Nav"
import NavAdmin from "./components/NavAdmin"
import Contacto from './pages/Contacto';
import Galeria from './pages/Galeria';
import Login from './pages/Login';
import AuthProvider from "./context/auth";
import Slider from './pages/Slider';
import PrivateRoute from "./components/privateRoute"
import Usuarios from './pages/Usuarios';
import Proyectos from './pages/Proyectos';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#26BEEF",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <HashRouter>
          <Routes>
            <Route exact path="/" element={<Nav children={<Home />} />} />
            <Route exact path="/contacto" element={<Nav children={<Contacto />} />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/admin" element={<PrivateRoute>
              <NavAdmin children={<Slider />} />
            </PrivateRoute>} />
            <Route exact path="/usuarios" element={<PrivateRoute>
              <NavAdmin children={<Usuarios />} />
            </PrivateRoute>} />
            <Route exact path="/proyectos" element={<PrivateRoute>
              <NavAdmin children={<Proyectos />} />
            </PrivateRoute>} />
            <Route exact path="/galeriaProyectos" element={<Nav children={<Galeria />} />} />
          </Routes>
        </HashRouter>
      </AuthProvider>
    </ThemeProvider >
  );
}

export default App;
