import React, { useState } from "react"
import { HashLink as Link } from 'react-router-hash-link';
import "../styles/nav.css"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Logo from "../img/logo_josemar.svg"
import iconoFacebook from "../img/icono_facebook.svg"
import iconoInstagram from "../img/icono_instagram.svg"
import iconoTelefono from "../img/icono_telefono.svg"
import iconoEmail from "../img/icono_email.svg"
import iconoUbicacion from "../img/icono_ubicacion.svg"

const Nav = ({ children }) => {
    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    return <div className="contenedorMain">
        <header>
            <div className="menuHeader">
                <IconButton onClick={toggleDrawer(true)}>
                    <MenuIcon style={{ fontSize: 30 }} />
                </IconButton>
            </div>
            <Link to="/" className="linkLogo"><img src={Logo} className="logo" alt="Logo josemar constructora" /></Link>
            <nav className="Nav">
                <div className="linkDiv">
                    <Link to="/">Inicio</Link>
                    <Link to="/#nosotros" smooth>Nosotros</Link>
                    <Link to="/galeriaProyectos">Proyectos</Link>
                </div>
                <div>
                    <Link to="/contacto" className="navContacto">Contacto</Link>
                </div>
            </nav>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                <div className="containerNavMobile">
                    <Link to="/" className="linkLogo" onClick={toggleDrawer(false)}>
                        <img src={Logo} className="logo" alt="Logo" />
                    </Link>
                    <Link to="/" onClick={toggleDrawer(false)}>Inicio</Link>
                    <Link to="/#nosotros" smooth onClick={toggleDrawer(false)}>Nosotros</Link>
                    <Link to="/galeriaProyectos" onClick={toggleDrawer(false)}>Proyectos</Link>
                    <Link to="/contacto" className="navContacto" onClick={toggleDrawer(false)}>Contacto</Link>
                </div>
            </Drawer>
        </header>
        <main>{children}</main>
        <footer>
            <div className="footerContainer">
                <div className="desc">
                    <p>Josemar  Constructora</p>
                    <p>Somos una empresa dedicada al diseño y ejecución de proyectos de construcciones civiles, con una amplia experiencia en el sector.</p>
                </div>
                <div className="enlaces">
                    <p>Nuestras redes</p>
                    <Link to="https://www.facebook.com/pyjosemar"><img src={iconoFacebook} alt="icono facebook" className="icono" /> Facebook</Link>
                    <Link to="https://www.instagram.com/josemarconstructora/"><img src={iconoInstagram} alt="icono instagram" className="icono" /> Instagram</Link>
                </div>
                <div className="contacto">
                    <p>Contactanos</p>
                    <Link to="https://maps.app.goo.gl/qAhJ8EYjbhckmjSL7"><img src={iconoUbicacion} alt="icono ubicacion" className="icono" /> Policarpo Cañete esq. San Blas, Fernando de la Mora</Link>
                    <Link to="mailto:ventas@josemar.com.py"><img src={iconoEmail} alt="icono correo" className="icono" /> ventas@josemar.com.py</Link>
                    <Link to="tel:0982 552582"><img src={iconoTelefono} alt="icono telefono" className="icono" /> 0982 552582</Link>
                </div>
            </div>
        </footer>
    </div>
}

export default Nav